<template>
  <div class="bp-input" :class="{ 'mb-8': !noLabel }">
    <div
      v-if="type != 'checkbox'"
      class="bp-input__field-container"
      :class="{ 'bp-input__field-container--float': float }"
    >
      <label
        v-if="!noLabel"
        class="bp-input__field-label text-body-small noselect"
        :class="{ 'bp-input__field-label--error': isError }"
        :for="inputId + defaultId"
        >{{ isError ? UI_ELEMENT(errorMessage) : UI_ELEMENT(label) }}</label
      >
      <component
        :is="type == 'textarea' ? type : 'input'"
        class="bp-input__field-input text-commerce-forms-select"
        :class="{
          'bp-input__field-input--center': center,
          'bp-input__field-input--error': isError,
          'bp-input__field-input--float': float,
          'bp-input__field-input--nolabel': noLabel
        }"
        :type="type"
        ref="input"
        :rows="rows"
        :maxlength="200"
        :value="value"
        :name="defaultId"
        :id="inputId + defaultId"
        :placeholder="UI_ELEMENT(placeholder)"
        @input="updateValue()"
        :autocomplete="autocomplete"
        :disabled="disabled"
        @focus="onFocus"
        @blur="onBlur"
        @mouseover="onMouseover"
        @mouseleave="onMouseLeave"
        tabindex="0"
      />
    </div>
    <div v-else class="bp-input__checkbox-container">
      <label
        @input="updateChecked()"
        :for="inputId + defaultId"
        class="bp-input__checkbox-label-outer flex--row-center-start"
      >
        <input
          class="bp-input__checkbox"
          type="checkbox"
          ref="checkbox"
          :checked="value"
          @change="updateChecked()"
          :name="defaultId"
          :id="inputId + defaultId"
          :disabled="disabled"
        />
        <div class="bp-input__checkbox-custom"></div>
        <slot>
          <div class="bp-input__checkbox-label-text flex--col-center-start">
            <span class="text-body-small text--clr-black">{{
              UI_ELEMENT(label)
            }}</span>
          </div>
        </slot>
      </label>
    </div>
  </div>
</template>

<script>
import { v4 as uuidv4 } from "uuid"

export default {
  name: "FormInput",

  props: {
    type: String,
    label: String,
    noLabel: Boolean,
    value: [String, Boolean],
    rows: Number,
    placeholder: String,
    inputId: String,
    autocomplete: String,
    disabled: Boolean,
    isError: Boolean,
    errorMessage: String,
    float: Boolean,
    center: Boolean
  },

  data: () => ({
    defaultId: null,
    labelData: "",
    placeholderData: ""
  }),

  computed: {
    getIsError() {
      return this.isError
    }
  },

  created() {
    this.defaultId = uuidv4()
    this.updateLabel()
    this.updatePlaceholder()
  },

  methods: {
    focus() {
      for (let el of ["input", "checkbox"]) {
        if (this.$refs[el]) {
          this.$refs[el].focus()
          break
        }
      }
    },

    updateValue() {
      this.$emit("input", this.$refs.input.value)
    },
    updateChecked() {
      this.$emit("input", this.$refs.checkbox.checked)
    },

    updateLabel(newVal) {
      // TODO: Adapt this to the GraphQL request on the other branch
      this.labelData = this.UI_ELEMENT(newVal || this.label)
    },

    updatePlaceholder(newVal) {
      // TODO: Adapt this to the GraphQL request on the other branch
      this.placeholderData = this.UI_ELEMENT(newVal || this.placeholder)
    },

    onFocus() {
      this.$emit("onFocus")
    },
    onBlur() {
      this.$emit("onBlur")
    },
    onMouseover() {
      this.$emit("onMouseover")
    },
    onMouseLeave() {
      this.$emit("onMouseLeave")
    }
  },

  watch: {
    label(newVal) {
      this.updateLabel(newVal)
    },

    placeholder(newVal) {
      this.updatePlaceholder(newVal)
    }
  }
}
</script>

<style lang="scss">
.bp-input {
  width: 100%;

  &__field-container {
    position: relative;
    width: 100%;
    margin: 8px 0;
    display: flex;
    flex-direction: column;

    &--float {
      margin: 0;
      flex-direction: column-reverse;
    }
  }

  &__field-label {
    position: relative;
    display: block;
    width: 100%;
    height: 1.5em;
    margin: 0 8px;

    &--error {
      color: getcolour(bleach_pink_dark);
    }
  }

  &__field-input {
    position: relative;
    width: 100%;
    // min- and max-width to control textarea resizing
    min-width: 100%;
    max-width: 100%;
    height: auto;
    min-height: 40px;
    padding: 8px 16px;
    margin: 4px 0 8px 0;
    width: 100%;
    border: 1px solid getcolour(bleach_black);
    border-radius: 0;

    &::placeholder {
      color: getcolour(bleach_grey_80);
    }

    &:focus {
      body.mouse-nav & {
        outline: getcolour(bleach_black) solid 1px;
      }
    }

    &:focus-visible {
      outline: getcolour(bleach_peach) solid 2px;
    }

    &--error {
      border: 1px solid getcolour(bleach_pink_dark);

      &:focus {
        body.mouse-nav & {
          outline: getcolour(bleach_pink_dark) solid 1px;
        }
      }
    }

    &--float {
      margin: 0;
      margin-bottom: 4px;
      padding-left: 0;
      padding-right: 0.5em;
      border: none;
      border-bottom: 1.5px solid getcolour(bleach_black);

      &:focus {
        body.mouse-nav & {
          // outline: getcolour(bleach_black) solid 1px;
          outline: none;
          filter: drop-shadow(0 1.5px 0 getcolour(bleach_black));
        }
      }
    }

    &--float#{&}--error {
      border-bottom: 1.5px solid getcolour(bleach_pink_dark);

      &:focus {
        body.mouse-nav & {
          // outline: getcolour(bleach_pink_dark) solid 1px;
          outline: none;
          filter: drop-shadow(0 1.5px 0 getcolour(bleach_pink_dark));
        }
      }
    }

    &--nolabel {
      border: none;
      margin: 0;

      &:focus {
        body.mouse-nav & {
          outline: none;
          filter: none;
        }
      }
    }

    &--center {
      text-align: center;
    }
  }

  &__checkbox {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  &__checkbox-label-outer {
    cursor: pointer;
    position: relative;
    width: 100%;
    flex-wrap: nowrap;

    .bp-input__checkbox-custom {
      position: relative;
      height: 20px;
      width: 20px;
      flex: 0 0 20px;
      background-color: getcolour(bleach_white);
      border-radius: 0;
      border: 1.5px solid getcolour(bleach_black);

      &:after {
        transition: opacity 0.05s linear;
        position: absolute;
        content: "";
        opacity: 0;
        left: 1px;
        top: 1px;
        width: calc(100% - 2px);
        height: calc(100% - 2px);
        background-color: getcolour(bleach_lime);
      }
    }

    input:checked + .bp-input__checkbox-custom:after {
      opacity: 1;
    }

    input:focus-visible + .bp-input__checkbox-custom {
      outline: getcolour(bleach_peach) solid 2px;
    }
  }

  &__checkbox-label-text {
    // margin-top: 0.25em;
    min-height: 20px;
    margin-left: 8px;
    flex: 1 1 auto;
    text-align: left;
  }
}
</style>
